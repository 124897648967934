exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aseoindustrial-js": () => import("./../../../src/pages/aseoindustrial.js" /* webpackChunkName: "component---src-pages-aseoindustrial-js" */),
  "component---src-pages-clientes-js": () => import("./../../../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laminas-de-seguridad-js": () => import("./../../../src/pages/laminas-de-seguridad.js" /* webpackChunkName: "component---src-pages-laminas-de-seguridad-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vidrios-aluminios-js": () => import("./../../../src/pages/vidrios-aluminios.js" /* webpackChunkName: "component---src-pages-vidrios-aluminios-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

